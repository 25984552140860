<template>
  <b-container class="pt-2">
    <b-form @submit.prevent="handleSubmit">
      <b-form-group label="Nom" label-for="name-input">
        <b-form-input
            id="name-input"
            v-model="$v.name.$model"
            type="text"
            required
            autofocus
            :state="$v.name.$dirty ? !$v.name.$invalid : null"
        />
        <b-form-invalid-feedback :state="!$v.name.$invalid">
          <span v-if="!$v.name.required">Vous devez renseigner ce champ</span>
          <span v-else-if="!$v.name.name">Ce champ ne peut contenir que des caractères alphabétiques</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
          label="Identifiant client OAuth"
          label-for="oauthClientId-input"

      >
        <b-form-input
            id="oauthClientId-input"
            v-model="$v.oauthClientId.$model"
            type="text"
            required
            :state="$v.oauthClientId.$dirty ? !$v.oauthClientId.$invalid : null"
        />
        <b-form-invalid-feedback :state="!$v.oauthClientId.$invalid">
          <span v-if="!$v.oauthClientId.required">Vous devez renseigner ce champ</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-row class="float-right">
        <b-button variant="secondary" class="mr-1" @click="close">Fermer</b-button>
        <b-button
            type="submit"
            variant="primary"
            class="ml-1"
            :disabled="$v.$invalid"
        >
          Ajouter
        </b-button>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {name} from "@/validators";

export default {
  data() {
    return {
      name: "",
      oauthClientId: "",
    };
  },
  validations: {
    name: {required, name},
    oauthClientId: {required},
  },
  methods: {
    reset() {
      this.name = "";
      this.oauthClientId = "";
    },
    close() {
      this.emitProvider(null);
    },
    handleSubmit() {
      this.emitProvider({
        type: "google",
        name: this.name,
        oauthClientId: this.oauthClientId,
      });
    },
    emitProvider(provider) {
      this.$emit("addProvider", provider);
      this.reset();
    },
  },
};
</script>