<template>
  <b-list-group>
    <b-list-group-item
        :key="index"
        v-for="(provider, index) in providers"
        class="
        d-flex
        justify-content-between
        align-items-center
        google-provider-list-content
      "
    >
      <div>
        {{ provider.name }}
        <span>
          <i>{{ provider.oauthClientId }}</i>
        </span>
      </div>
      <div class="row justify-content-end mr-0">
        <EditDeleteButtonGroup
            v-if="provider.uid"
            @edit="editProvider(provider)"
            @delete="$emit('remove', provider)"
            :prevent-delete="false"
        />
        <DeleteButton
            v-else
            class="no-border"
            @delete="$emit('remove', provider)"
            :prevent-delete="false"
        />
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import EditDeleteButtonGroup from "@/components/buttons/EditDeleteButtonGroup";
import DeleteButton from "@/components/buttons/DeleteButton";

export default {
  components: {
    DeleteButton,
    EditDeleteButtonGroup,
  },
  props: {
    providers: {
      type: Array,
      required: true,
    },
  },
  methods: {
    async editProvider(provider) {
      await this.$router.push({
        name: 'googleProvider',
        params: {uid: provider.uid}
      })
    },
    async deleteProvider(provider) {
      try {
        await this.$store.dispatch("clientApps/providers/deleteProvider", {
          type: "google",
          uid: provider.uid,
        });
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title:
              "Une erreur est survenue lors de la suppression du fournisseur tier d'authentification Google",
          text: e.message,
        });
        return;
      }

      await this.$swal.fire({
        icon: "success",
        title: "Fournisseur tier d'authentification Google supprimé",
      });
    },
  },
};
</script>

<style scoped>
.google-provider-list-content span {
  color: grey;
  opacity: 0;
  transition: opacity 0.75s;
}

.google-provider-list-content:hover span {
  opacity: 1;
}
</style>