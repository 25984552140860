<template>
  <b-container class="pt-2">
    <b-form @submit.prevent="handleSubmit">
      <b-form-group
          label="Identifiant de projet"
          label-for="project-id-input"

      >
        <b-form-input
            id="project-id-input"
            v-model="$v.projectId.$model"
            type="text"
            required
            autofocus
            placeholder="Renseignez un identifiant de projet"
            :state="$v.projectId.$dirty ? !$v.projectId.$invalid : null"
        />
        <b-form-invalid-feedback :state="!$v.projectId.$invalid">
          <span v-if="!$v.projectId.required">Vous devez renseigner ce champ</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <div class="float-right">
        <b-button variant="secondary" class="mr-1" @click="close">
          Fermer
        </b-button>
        <b-button
            type="submit"
            variant="primary"
            class="ml-1"
            :disabled="$v.$invalid"
        >
          Ajouter
        </b-button>
      </div>
    </b-form>
  </b-container>
</template>

<script>
import {required} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      projectId: "",
    };
  },
  validations: {
    projectId: {required},
  },
  methods: {
    reset() {
      this.projectId = "";
    },
    close() {
      this.emitProvider(null);
    },
    handleSubmit() {
      this.emitProvider({
        type: "firebase",
        projectId: this.projectId,
      });
    },
    emitProvider(provider) {
      this.$emit("addProvider", provider);
      this.reset();
    },
  },
};
</script>