<template>
  <b-modal
      id="add-provider-modal"
      title="Ajouter un fournisseur tier d'authentification"
      size="xl"
      :modal-footer="false"
      @hide="reset"
  >
    <b-tabs v-model="tabIndex" justified>
      <b-tab>
        <template #title>
          <img class="icon" alt="Vue logo" src="@/assets/google-icon.svg"/>
          Google
        </template>
        <GoogleProviderForm
            ref="googleProviderForm"
            @addProvider="addGoogleProvider"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <img class="icon" alt="Vue logo" src="@/assets/firebase-icon.svg"/>
          Firebase
        </template>
        <FirebaseProviderForm
            ref="firebaseProviderForm"
            @addProvider="addFirebaseProvider"
        />
      </b-tab>
    </b-tabs>
    <template #modal-footer><span></span></template>
  </b-modal>
</template>

<script>
import GoogleProviderForm from "./GoogleProviderForm";
import FirebaseProviderForm from "./FirebaseProviderForm";

export default {
  data() {
    return {
      tabIndex: 0,
    };
  },
  components: {
    FirebaseProviderForm,
    GoogleProviderForm,
  },
  methods: {
    reset() {
      this.tabIndex = 0;
      this.$refs.googleProviderForm.reset();
      this.$refs.firebaseProviderForm.reset();
    },
    addGoogleProvider(provider) {
      this.addProvider("google", provider);
    },
    addFirebaseProvider(provider) {
      this.addProvider("firebase", provider);
    },
    addProvider(type, provider) {
      this.$nextTick(() => {
        this.$bvModal.hide("add-provider-modal");
      });

      if (provider) {
        this.$emit('addProvider', {type, provider});
      }
    },
  },
};
</script>

<style scoped>
img.icon {
  width: 0.8rem;
}

.modal-footer {
  display: none;
}
</style>