<template>
  <div>
    <UpdateClientAppForm
        v-if="ready"
        :clientApp="clientApp"
        :providers="providers"
    />
    <div v-else>
      <b-row>
        <b-col/>
        <b-col cols="1">
          <AppPulsingIcon/>
        </b-col>
        <b-col/>
      </b-row>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import UpdateClientAppForm from "@/components/clientApps/UpdateClientAppForm";
import AppPulsingIcon from "@/components/AppPulsingIcon";

export default {
  components: {
    UpdateClientAppForm,
    AppPulsingIcon,
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ready: false,
    };
  },
  computed: {
    ...mapState({
      clientApp: (state) => state.clientApps.clientApp,
      providers: (state) => state.clientApps.providers.all,
    }),
  },
  async created() {
    try {
      await this.$store.dispatch("clientApps/fetchClientApp", this.uid);
    } catch (e) {
      await this.$swal.fire({
        icon: "error",
        title:
            "Une erreur est survenue lors de la récupération de l'application client",
        text: e.message,
      });
      return;
    }
    this.ready = true;
  },
};
</script>

