<template>
  <div>
    <b-row>
      <b-col>
        <h6>Google</h6>
        <GoogleProvidersList
            v-if="googleProviders.length > 0"
            :providers="googleProviders"
            @remove="removeGoogleProvider"
        />
        <p v-else>
          Aucun fournisseur d'authentification Google n'est configuré pour cette
          application
        </p>
      </b-col>
    </b-row>
    <br/>
    <b-row>
      <b-col>
        <h6>Firebase</h6>
        <FirebaseProvidersList
            v-if="firebaseProviders.length > 0"
            :providers="firebaseProviders"
            @remove="removeFirebaseProvider"
        />
        <p v-else>
          Aucun fournisseur d'authentification Firebase n'est configuré pour cette
          application
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GoogleProvidersList from "./GoogleProvidersList";
import FirebaseProvidersList from "./FirebaseProvidersList";

export default {
  props: {
    providers: {
      type: Object,
      required: true,
    },
  },
  components: {
    GoogleProvidersList,
    FirebaseProvidersList,
  },
  computed: {
    googleProviders() {
      if (!this.providers.google) {
        return [];
      }

      return this.providers.google
          .slice()
          .sort((a, b) =>
              a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
          );
    },
    firebaseProviders() {
      if (!this.providers.firebase) {
        return [];
      }

      return this.providers.firebase
          .slice()
          .sort((a, b) =>
              a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
          );
    },
  },
  methods: {
    removeGoogleProvider(provider) {
      this.removeProvider("google", provider);
    },
    removeFirebaseProvider(provider) {
      this.removeProvider("firebase", provider);
    },
    removeProvider(type, provider) {
      this.$emit("remove", {type, provider});
    },
  },
};
</script>
