<template>
  <div>
    <b-form @submit.prevent="updateClientApp">
      <b-form-group label="Nom" label-for="name-input">
        <b-form-input
            id="name-input"
            v-model="$v.ca.name.$model"
            type="text"
            required
            placeholder="Renseignez un nom"
            :state="$v.ca.name.$dirty ? !$v.ca.name.$invalid : null"
        />
        <b-form-invalid-feedback :state="!$v.ca.name.$invalid">
          <span v-if="!$v.ca.name.required">Vous devez renseigner ce champ</span>
          <span v-else-if="!$v.ca.name.name">Ce champ ne peut contenir que des caractères alphabétiques</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group>
        <template slot="label">
          Fournisseurs tiers d'authentification
          <font-awesome-icon
              icon="plus-circle"
              :style="{ color: 'green' }"
              v-b-modal.add-provider-modal
          />
        </template>
        <ProvidersList :providers="p" @remove="removeProvider"/>
      </b-form-group>
      <b-button type="submit" variant="primary" :disabled="$v.$invalid">
        Envoyer
      </b-button>
    </b-form>
    <AddProviderModal @addProvider="addProvider"/>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {name} from "@/validators";
import AddProviderModal from "./providers/modals/AddProviderModal";
import ProvidersList from "./providers/ProvidersList";

export default {
  components: {
    ProvidersList,
    AddProviderModal,
  },
  props: {
    clientApp: {
      type: Object,
      required: true,
    },
    providers: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ca: Object.assign({}, this.clientApp), // copy state to avoid mutation
      p: JSON.parse(JSON.stringify(this.providers)), // copy state to avoid mutation
    };
  },
  validations: {
    ca: {
      name: {required, name},
    },
  },
  methods: {
    addProvider({type, provider}) {
      if (!provider) {
        return;
      }
      this.p[type].push(provider);
    },
    removeProvider({type, provider}) {
      if (!provider) {
        return;
      }
      this.p[type] = this.p[type].filter((p) => p.uid !== provider.uid);
    },
    async updateClientApp() {
      const addedProviders = {
        google: this.p.google.filter((p) => !p.uid),
        firebase: this.p.firebase.filter((p) => !p.uid),
      };
      const removedProviders = {
        google: this.providers.google.filter(
            (googleProvider) =>
                !this.p.google.some((gp) => googleProvider.uid === gp.uid)
        ),
        firebase: this.providers.firebase.filter(
            (firebaseProvider) =>
                !this.p.firebase.some((fp) => firebaseProvider.uid === fp.uid)
        ),
      };

      try {
        await Promise.all([
          this.$store.dispatch("clientApps/updateClientApp", this.ca),
          ...addedProviders.google.map((provider) =>
              this.$store.dispatch("clientApps/providers/addProvider", {
                type: "google",
                clientApp: this.clientApp,
                provider,
              })
          ),
          ...addedProviders.firebase.map((provider) =>
              this.$store.dispatch("clientApps/providers/addProvider", {
                type: "firebase",
                clientApp: this.clientApp,
                provider,
              })
          ),
          ...removedProviders.google.map((provider) =>
              this.$store.dispatch("clientApps/providers/deleteProvider", {
                type: "google",
                uid: provider.uid,
              })
          ),
          ...removedProviders.firebase.map((provider) =>
              this.$store.dispatch("clientApps/providers/deleteProvider", {
                type: "firebase",
                uid: provider.uid,
              })
          ),
        ]);
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title:
              "Une erreur est survenue lors de la mise à jour de l'application cliente",
          text: e.message,
        });
        return;
      }

      await this.$swal.fire({
        icon: "success",
        title: "Application cliente modifiée",
      });
    },
  },
};
</script>

